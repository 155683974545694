import React, { useEffect, useState } from 'react';
import { Star } from 'lucide-react';

interface SocialProofProps {
  userCount: number;
  className?: string;
}

const SocialProof: React.FC<SocialProofProps> = ({ userCount, className = '' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  
  // List of different user types to animate through
  const userTypes = [
    'Vibe Coders',
    'Writers',
    'Students',
    'Vibe coders',
    'Entrepreneurs'
  ];

  // Actual testimonial avatar images from the Testimonials component
  const avatars = [
    'https://yt3.ggpht.com/ytc/AIdro_mGGhXHzQ5kg3cgWQ0wHrN19u3c_-i6x6ZUsrnshgayEQpQ=s88-c-k-c0x00ffffff-no-rj',
    'https://lh3.googleusercontent.com/a-/ALV-UjUaEPzphF3AGS-SgxyaKTPdO3oPuqXxrOdjvKwOobFIeyAqIn-r=s128-p',
    'https://lh3.googleusercontent.com/a-/ALV-UjXbL6DfTPZXuCjkBUaxnKaHCr4EGDvkr8PWPBr_uCk6baLTWcY=s160-p-k-rw-no',
    'https://lh3.googleusercontent.com/a-/ALV-UjVpIp7hfcofEZamjcHX0otFB_k3c-K0yXKd8z4eWWxOZ966rydikCV7rg=s128-p',
    'https://pbs.twimg.com/profile_banners/176277412/1693251251/1500x500'
  ];

  // Improved animation effect with fade transition
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      
      // Wait for fade out animation to complete before changing text
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % userTypes.length);
        setIsAnimating(false);
      }, 500); // Half of the total animation duration
      
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval);
  }, [userTypes.length]);

  return (
    <div className={`flex flex-col items-center ${className}`}>
      {/* User avatars and stars */}
      <div className="flex items-center justify-center mb-3">
        <div className="flex -space-x-3 mr-4">
          {avatars.map((avatar, index) => (
            <div 
              key={index} 
              className="w-8 h-8 rounded-full border-2 border-white overflow-hidden bg-gray-200"
            >
              <img 
                src={avatar} 
                alt={`User ${index + 1}`} 
                className="w-full h-full object-cover"
                onError={(e) => {
                  // Fallback if image fails to load - generate a colored background with initials
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  target.parentElement!.classList.add('bg-gradient-to-br', 'from-blue-200', 'to-purple-200');
                  target.parentElement!.innerHTML = `<div class="flex items-center justify-center w-full h-full text-xs font-bold text-gray-700">U${index + 1}</div>`;
                }}
              />
            </div>
          ))}
        </div>
        
        <div className="flex">
          {[...Array(5)].map((_, index) => (
            <Star key={index} className="w-4 h-4 text-yellow-400 fill-yellow-400" />
          ))}
        </div>
      </div>
      
      {/* Animated text with perfect alignment and highlighting */}
      <div className="inline-flex items-center justify-center text-sm text-gray-700 font-medium">
        <span className="inline-flex items-center">
          <span>Loved by</span>
          <span className="font-bold text-gray-900 mx-1">{userCount}+</span>
        </span>
        
        <div className="relative h-5 overflow-hidden" style={{ width: '130px' }}>
          {userTypes.map((type, index) => (
            <div 
              key={index}
              className="absolute inset-0 flex items-center justify-start transition-all duration-700 ease-in-out"
              style={{ 
                opacity: currentIndex === index ? (isAnimating ? 0 : 1) : 0,
                transform: currentIndex === index 
                  ? (isAnimating ? 'translateY(-10px)' : 'translateY(0)') 
                  : 'translateY(10px)'
              }}
            >
              <span className="relative text-gray-900 bg-amber-100 border border-amber-200 box-decoration-clone px-1.5 rounded-sm leading-[1.45] transition-colors duration-300">
                {type}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialProof; 
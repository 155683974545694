import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { Twitter, Github } from 'lucide-react';
import DownloadButton from './DownloadButton';
import GitHubStarButton from '../common/GitHubStarButton';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const isLandingPage = location.pathname === '/';

  const navLinks = [
    { name: 'Features', to: 'features' },
    { name: 'Testimonials', to: 'testimonials' },
    { name: 'Pricing', to: 'pricing' }
  ];

  return (
    <div className="relative min-h-screen bg-gray-50 flex flex-col">
      {/* Modern grid background */}
      <div className="fixed inset-0 pointer-events-none">
        {/* Gradient background */}
        <div className="absolute inset-0 bg-gradient-to-b from-white via-gray-50 to-white" />
        
        {/* Grid pattern */}
        <div className="absolute inset-0" 
          style={{
            backgroundImage: `
              radial-gradient(circle at 1px 1px, rgba(0, 0, 0, 0.03) 1px, transparent 0),
              linear-gradient(rgba(0, 0, 0, 0.02) 1px, transparent 1px),
              linear-gradient(90deg, rgba(0, 0, 0, 0.02) 1px, transparent 1px)
            `,
            backgroundSize: '32px 32px, 64px 64px, 64px 64px',
            backgroundPosition: '0 0, 0 0, 0 0'
          }} 
        />

        {/* Ambient light effects */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-purple-100/50 rounded-full blur-3xl" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-blue-100/50 rounded-full blur-3xl" />
      </div>
      
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50">
        {/* Enhanced glassmorphism backdrop */}
        <div className="absolute inset-0 bg-gradient-to-r from-white/90 via-white/95 to-white/90 backdrop-blur-xl border-b border-gray-200/30 shadow-[0_2px_20px_-10px_rgba(0,0,0,0.1)]" />
        
        <div className="relative max-w-7xl mx-auto px-4 md:px-6">
          {/* Main header content with centered layout */}
          <div className="h-24 flex flex-col items-center justify-center relative">
            {/* Logo centered */}
            <Link to="/" className="flex items-center gap-3 group absolute left-0 top-1/2 -translate-y-1/2">
              <div className="relative">
                <div className="absolute -inset-[2px] rounded-xl bg-gradient-to-r from-purple-200/50 to-blue-200/50 blur-sm opacity-0 group-hover:opacity-100 transition-all duration-500" />
                <div className="relative bg-white/50 border border-gray-200/50 rounded-xl p-1.5 backdrop-blur-sm transition-all duration-300 group-hover:border-gray-300/50">
                  <picture>
                    <source
                      srcSet="/icons/optimized/appIcon-32.webp 1x, /icons/optimized/appIcon-64.webp 2x"
                      type="image/webp"
                      media="(max-width: 768px)"
                    />
                    <source
                      srcSet="/icons/optimized/appIcon-64.webp"
                      type="image/webp"
                      media="(min-width: 769px)"
                    />
                    <source
                      srcSet="/icons/optimized/appIcon-32.png 1x, /icons/optimized/appIcon-64.png 2x"
                      media="(max-width: 768px)"
                    />
                    <source
                      srcSet="/icons/optimized/appIcon-64.png"
                      media="(min-width: 769px)"
                    />
                    <img 
                      src="/icons/optimized/appIcon-32.png"
                      alt="VoiceInk Logo" 
                      className="w-8 h-8 md:w-9 md:h-9 rounded-lg opacity-90 group-hover:opacity-100 transition-opacity"
                      width={36}
                      height={36}
                    />
                  </picture>
                </div>
              </div>
              <span className="text-xl md:text-2xl font-black bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 bg-clip-text text-transparent">
                VoiceInk
              </span>
            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center gap-10">
              {isLandingPage && navLinks.map((link) => (
                <ScrollLink
                  key={link.name}
                  to={link.to}
                  smooth={true}
                  duration={500}
                  offset={-96}
                  className="relative text-[15px] font-semibold text-gray-900 transition-all duration-300 cursor-pointer"
                >
                  {link.name}
                </ScrollLink>
              ))}
            </nav>

            {/* Actions - Visible on all screens */}
            <div className="flex items-center gap-4 sm:gap-6 absolute right-0 top-1/2 -translate-y-1/2">
              <div className="hidden sm:block">
                <GitHubStarButton repo="Beingpax/VoiceInk" />
              </div>
              {isLandingPage && (
                <ScrollLink
                  to="pricing"
                  smooth={true}
                  duration={500}
                  offset={-96}
                >
                  <DownloadButton size="small" className="shadow-sm hover:shadow-md transition-shadow duration-300">
                    Download
                  </DownloadButton>
                </ScrollLink>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <div className="relative pt-16 md:pt-20 flex-grow">
        {children}
      </div>

      {/* Footer */}
      <footer className="relative mt-12 border-t border-gray-200/50 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Brand */}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <picture>
                  <source srcSet="/icons/optimized/appIcon-32.webp" type="image/webp" />
                  <img
                    src="/icons/optimized/appIcon-32.png"
                    alt="VoiceInk Logo"
                    width={32}
                    height={32}
                    className="opacity-90 hover:opacity-100 transition-opacity"
                  />
                </picture>
                <span className="text-gray-900 font-bold text-xl transition-colors">
                  VoiceInk
                </span>
              </div>
              <p className="text-sm text-gray-600">
                Made with ❤️ by <a href="https://x.com/JoshiPax" target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:text-gray-900 transition-colors">Pax</a>
              </p>
              <div className="flex items-center space-x-4">
                <a href="https://twitter.com/joshipax" target="_blank" rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-900 transition-colors">
                  <Twitter className="w-5 h-5" />
                </a>
                <a href="https://github.com/beingpax" target="_blank" rel="noopener noreferrer"
                  className="text-gray-500 hover:text-gray-900 transition-colors">
                  <Github className="w-5 h-5" />
                </a>
              </div>
            </div>

            {/* Product */}
            <div>
              <h3 className="text-gray-900 font-semibold mb-4">Product</h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/features" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Features
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/buy" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Buy
                  </Link>
                </li>
                <li>
                  <Link to="/testimonials" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="text-gray-600 hover:text-gray-900 transition-colors">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="text-gray-900 font-semibold mb-4">Resources</h3>
              <ul className="space-y-3">
                <li>
                  <Link to="/best-dictation-apps" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Best Dictation Apps
                  </Link>
                </li>
                <li>
                  <a 
                    href="https://github.com/Beingpax/VoiceInk/releases" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Changelog
                  </a>
                </li>
                <li>
                  <a 
                    href="https://github.com/Beingpax/VoiceInk/issues" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Roadmap
                  </a>
                </li>
                <li>
                  <Link to="/privacy" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-gray-600 hover:text-gray-900 transition-colors">
                    Terms of Service
                  </Link>
                </li>
              </ul>
            </div>

            {/* Community */}
            <div>
              <h3 className="text-gray-900 font-semibold mb-4">Community</h3>
              <ul className="space-y-3">
                <li>
                  <a href="mailto:prakashjoshipax@gmail.com" 
                    className="text-gray-600 hover:text-gray-900 transition-colors">
                    Email Support
                  </a>
                </li>
                <li>
                  <a 
                    href="https://discord.com/invite/xryDy57nYD" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Discord
                  </a>
                </li>
                <li>
                  <a 
                    href="https://github.com/beingpax/VoiceInk" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    GitHub
                  </a>
                </li>
                <li>
                  <a 
                    href="https://voiceink.affonso.io" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    Affiliate
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Volume2, VolumeX } from 'lucide-react';

interface VideoPlayerProps {
  src: string;
  className?: string;
  containerClassName?: string;
  poster?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  className = "",
  containerClassName = "",
  poster = ""
}) => {
  const [isMuted, setIsMuted] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleIntersection = useCallback((entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting && !isVideoLoaded) {
      setIsVideoLoaded(true);
    }
    if (videoRef.current) {
      if (entry.isIntersecting) {
        // Reset and play only if video is in viewport
        videoRef.current.currentTime = 0;
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise.catch(() => {
            console.log('Autoplay failed');
          });
        }
      } else {
        videoRef.current.pause();
        videoRef.current.muted = true;
        setIsMuted(true);
      }
    }
  }, [isVideoLoaded]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '100px 0px',
      threshold: 0.2
    });

    const currentContainer = containerRef.current;
    if (currentContainer) {
      observer.observe(currentContainer);
    }

    return () => {
      if (currentContainer) {
        observer.disconnect();
      }
    };
  }, [handleIntersection]);

  const handleVideoLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  const toggleMute = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  }, [isMuted]);

  return (
    <div 
      ref={containerRef}
      className={`relative overflow-hidden ${containerClassName}`}
    >
      <div 
        className="relative aspect-video bg-[#1A1A1A] cursor-pointer group"
        onClick={toggleMute}
      >
        {/* Shimmer loading effect */}
        {isLoading && (
          <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/[0.07] to-transparent
            animate-[shimmer_2s_infinite]"
            style={{
              backgroundSize: '200% 100%',
              animation: 'shimmer 2s infinite',
            }}
          />
        )}
        
        <video
          ref={videoRef}
          className={`absolute inset-0 w-full h-full object-cover ${className}`}
          loop
          playsInline
          muted={isMuted}
          preload="metadata"
          onLoadedData={handleVideoLoad}
          poster={poster}
        >
          {isVideoLoaded && <source src={src} type="video/mp4" />}
        </video>

        {/* Mute/Unmute Indicator Overlay */}
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <div className="p-4 rounded-full bg-black/50 backdrop-blur-sm">
            {isMuted ? (
              <VolumeX className="w-8 h-8 text-white" />
            ) : (
              <Volume2 className="w-8 h-8 text-white" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer; 
import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import VideoPlayer from './common/VideoPlayer';
import AppMarquee from './AppMarquee';
import DownloadButton from './landing/DownloadButton';
import SocialProof from './common/SocialProof';
import HeroTestimonials from './landing/HeroTestimonials';
import { ShieldCheck, Github, Zap } from 'lucide-react';

// Feature badge component
const FeatureBadge: React.FC<{ icon: React.ReactNode; text: string }> = ({ icon, text }) => (
  <div className="flex items-center gap-1.5 px-3 py-1.5 rounded-full bg-white/50 border border-gray-200/50 shadow-sm backdrop-blur-sm">
    {icon}
    <span className="text-sm font-medium text-gray-900">{text}</span>
  </div>
);

const Hero = () => {
  return (
    <section className="flex flex-col items-center justify-center py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 w-full">
        <div className="text-center max-w-4xl mx-auto">
          {/* Main Headline */}
          <h1 className="text-5xl md:text-7xl font-black mb-6 tracking-tight">
            <span className="block text-gray-900 mb-2">WRITE AT THE</span>
            <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-blue-600 text-transparent bg-clip-text 
              animate-gradient bg-300% font-black">
              SPEED OF THOUGHT
            </span>
          </h1>

          {/* Compelling Subheadline */}
          <p className="text-xl md:text-2xl text-gray-600 mb-12 max-w-3xl mx-auto leading-relaxed whitespace-normal overflow-visible">
            VoiceInk uses
            <span className="text-gray-900 font-semibold"> local AI models </span>
            to instantly transcribe what you say with near-perfect accuracy and 
            <span className="text-gray-900 font-semibold"> complete privacy</span>.
          </p>

          {/* Feature Badges */}
          <div className="flex flex-wrap items-center justify-center gap-3 mb-8">
            <FeatureBadge 
              icon={<ShieldCheck className="w-4 h-4 text-green-600" />} 
              text="100% Private" 
            />
            <FeatureBadge 
              icon={<Github className="w-4 h-4 text-gray-900" />} 
              text="Open Source" 
            />
            <FeatureBadge 
              icon={<Zap className="w-4 h-4 text-amber-500" />} 
              text="Lightning Fast" 
            />
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-8">
            <ScrollLink to="pricing" smooth={true} duration={500} offset={window.innerWidth >= 768 ? -80 : -64} className="w-auto">
              <DownloadButton>
                Download Now
              </DownloadButton>
            </ScrollLink>
          </div>
          
          {/* Social Proof */}
          <SocialProof userCount={457} className="mb-16" />
        </div>

        {/* Hero Testimonials - Full Width */}
        <div className="w-full -mx-4 sm:-mx-6 lg:-mx-8 px-4 sm:px-6 lg:px-8">
          <HeroTestimonials />
        </div>

        {/* Video Section */}
        <div 
          className="relative mx-auto overflow-visible w-full max-w-5xl mt-16"
        >
          <div className="aspect-video w-full">
            <VideoPlayer 
              src="/videos/FastVoiceInk.mp4"
              containerClassName="rounded-2xl bg-white shadow-xl border border-gray-200/50 w-full h-full"
              className="transition-all duration-300"
            />
          </div>
        </div>
      </div>

      {/* App Marquee Section - Full Width */}
      <div className="w-full mt-20">
        <AppMarquee />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mt-12">
          <h2 className="text-3xl md:text-4xl font-bold">
            <span className="text-gray-900">Works Across </span>
            <span className="bg-gradient-to-r from-blue-600 via-purple-600 to-blue-600 text-transparent bg-clip-text animate-gradient bg-300%">All Apps</span>
          </h2>
          <p className="text-gray-600 mt-3 text-lg">Use your voice to write anywhere, in any application</p>
        </div>
      </div>
    </section>
  );
};

export default Hero; 
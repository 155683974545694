import React from 'react';

interface Testimonial {
  id: number;
  name: string;
  role: string;
  content: string;
  image?: string;
  highlights?: string[];
  rating: number;
}

// Function to highlight text with specified phrases
const highlightText = (text: string, highlights: string[] = []) => {
  if (!highlights.length) return text;

  let result = [];
  let lastIndex = 0;

  // Sort highlights by length (descending) to handle overlapping matches
  const sortedHighlights = [...highlights].sort((a, b) => b.length - a.length);

  // Find all matches and their positions
  interface TextMatch {
    start: number;
    end: number;
    text: string;
  }

  const matches: TextMatch[] = [];
  sortedHighlights.forEach(highlight => {
    const regex = new RegExp(highlight, 'gi');
    let match;
    while ((match = regex.exec(text)) !== null) {
      matches.push({
        start: match.index,
        end: match.index + highlight.length,
        text: match[0]
      });
    }
  });

  // Sort matches by start position
  matches.sort((a, b) => a.start - b.start);

  // Build the result with highlighted spans
  matches.forEach((match, index) => {
    if (match.start > lastIndex) {
      result.push(text.substring(lastIndex, match.start));
    }
    result.push(
      <span
        key={index}
        className="relative inline font-medium"
      >
        <span className="relative text-gray-900
          bg-amber-100
          border border-amber-200
          box-decoration-clone 
          px-1.5
          rounded-sm
          leading-[1.45]
          transition-colors duration-300"
        >
          {match.text}
        </span>
      </span>
    );
    lastIndex = match.end;
  });

  if (lastIndex < text.length) {
    result.push(text.substring(lastIndex));
  }

  return result;
};

// Star Rating Component
const StarRating: React.FC<{ rating: number }> = ({ rating }) => {
  return (
    <div className="flex items-center gap-0.5">
      {[1, 2, 3, 4, 5].map((star) => (
        <svg
          key={star}
          className={`w-4 h-4 ${star <= rating ? 'text-amber-500' : 'text-gray-300'}`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ))}
    </div>
  );
};

// Selected testimonials for the hero section
const heroTestimonials: Testimonial[] = [
  {
    id: 9,
    name: "Robert Masata",
    role: "",
    content: "I have been using MacWhisper for the last months and have dreamed about virtually all the functions you have built in VoiceInk. Have bought the license after 7 Minutes, not 7 days!🔥",
    image: "/testimonials/Robert.jpg",
    highlights: ["bought the license after 7 Minutes, not 7 days!🔥"],
    rating: 5
  },
  {
    id: 2,
    name: "Artawower LH",
    role: "",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUaEPzphF3AGS-SgxyaKTPdO3oPuqXxrOdjvKwOobFIeyAqIn-r=s128-p",
    content: "I use it almost every day, switched from wispr flow and I really like it.",
    highlights: ["switched from wispr flow and I really like it"],
    rating: 5
  },
  {
    id: 12,
    name: "Bryce Johnson",
    role: "",
    content: "Voiceink has grown so much just in the few months that I've had it. Awesome support and development team. I use it every day. My best purchase so far in 2025",
    image: "/testimonials/Bryce.png",
    highlights: ["My best purchase so far in 2025"],
    rating: 5
  }
];

const HeroTestimonials: React.FC = () => {
  return (
    <div className="w-full mt-16">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-8 max-w-[1400px] mx-auto">
        {heroTestimonials.map((testimonial) => (
          <div
            key={testimonial.id}
            className="relative bg-white rounded-2xl p-6 lg:p-8 shadow-sm border border-gray-200/50 transition-all duration-300 hover:shadow-md"
          >
            {/* Content First */}
            <div className="text-gray-600 leading-relaxed text-base lg:text-lg mb-6">
              {highlightText(testimonial.content, testimonial.highlights)}
            </div>

            {/* User Info and Rating at Bottom */}
            <div className="flex items-start justify-between pt-4 border-t border-gray-100">
              <div className="flex items-center gap-3 lg:gap-4">
                {testimonial.image ? (
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-10 h-10 lg:w-12 lg:h-12 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-10 h-10 lg:w-12 lg:h-12 rounded-full bg-gradient-to-br from-purple-200 to-blue-200 flex items-center justify-center">
                    <span className="text-sm lg:text-base font-semibold text-gray-700">
                      {testimonial.name.charAt(0)}
                    </span>
                  </div>
                )}
                <div>
                  <div className="font-semibold text-gray-900 text-base lg:text-lg">{testimonial.name}</div>
                  {testimonial.role && (
                    <div className="text-sm lg:text-base text-gray-600">{testimonial.role}</div>
                  )}
                </div>
              </div>
              <StarRating rating={testimonial.rating} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroTestimonials; 
import React from 'react';
import { motion } from 'framer-motion';
import '../styles/app-marquee.css';

interface App {
  name: string;
  icon: string;
  iconWebp?: string;
}

const apps: App[] = [
  { 
    name: 'Telegram', 
    icon: '/icons/apps/optimized/Telegram_logo.svg'
  },
  { 
    name: 'Arc', 
    icon: '/icons/apps/optimized/arc.png',
    iconWebp: '/icons/apps/optimized/arc.webp'
  },
  { 
    name: 'Obsidian', 
    icon: '/icons/apps/optimized/obsidian.png',
    iconWebp: '/icons/apps/optimized/obsidian.webp'
  },
  { 
    name: 'Cursor', 
    icon: '/icons/apps/optimized/cursor.png',
    iconWebp: '/icons/apps/optimized/cursor.webp'
  },
  { 
    name: 'Perplexity', 
    icon: '/icons/apps/optimized/perplexity.png',
    iconWebp: '/icons/apps/optimized/perplexity.webp'
  },
  { 
    name: 'Claude', 
    icon: '/icons/apps/optimized/claude.png',
    iconWebp: '/icons/apps/optimized/claude.webp'
  },
  { 
    name: 'DeepSeek', 
    icon: '/icons/apps/optimized/deepseek.png',
    iconWebp: '/icons/apps/optimized/deepseek.webp'
  },
  { 
    name: 'Notion', 
    icon: '/icons/apps/optimized/notion.png',
    iconWebp: '/icons/apps/optimized/notion.webp'
  },
  { 
    name: 'Windsurf', 
    icon: '/icons/apps/optimized/windsurf.svg'
  },
  { 
    name: 'Superhuman', 
    icon: '/icons/apps/optimized/superhuman.png',
    iconWebp: '/icons/apps/optimized/superhuman.webp'
  },
  { 
    name: 'Gmail', 
    icon: '/icons/apps/optimized/gmail.svg'
  },
  { 
    name: 'Mail', 
    icon: '/icons/apps/optimized/apple-mail.png',
    iconWebp: '/icons/apps/optimized/apple-mail.webp'
  },
  { 
    name: 'Google Docs', 
    icon: '/icons/apps/optimized/google-docs.svg'
  },
  { 
    name: 'Slack', 
    icon: '/icons/apps/optimized/slack.svg'
  },
  { 
    name: 'WhatsApp', 
    icon: '/icons/apps/optimized/whatsapp.svg'
  },
  { 
    name: 'Signal', 
    icon: '/icons/apps/optimized/signal.svg'
  },
  { 
    name: 'Notes', 
    icon: '/icons/apps/optimized/notes.png',
    iconWebp: '/icons/apps/optimized/notes.webp'
  }
];

const AppMarquee: React.FC = () => {
  // Duplicate apps array for seamless loop
  const duplicatedApps = React.useMemo(() => [...apps, ...apps], []);

  return (
    <div className="w-full overflow-hidden">
      <div className="relative w-full">
        {/* Gradient masks */}
        <div className="absolute left-0 top-0 bottom-0 w-24 bg-gradient-to-r from-white to-transparent z-10" />
        <div className="absolute right-0 top-0 bottom-0 w-24 bg-gradient-to-l from-white to-transparent z-10" />
        
        {/* Marquee container */}
        <div className="app-marquee-container">
          <div className="app-marquee-content">
            {duplicatedApps.map((app, index) => (
              <motion.div
                key={`${app.name}-${index}`}
                className="app-card group relative isolate px-3 py-2"
                initial={{ opacity: 0.5, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="flex flex-col items-center gap-2">
                  <div className="relative w-12 h-12 sm:w-16 sm:h-16">
                    {app.iconWebp ? (
                      <picture>
                        <source srcSet={app.iconWebp} type="image/webp" />
                        <img
                          src={app.icon}
                          alt={`${app.name} icon`}
                          width={64}
                          height={64}
                          loading="lazy"
                          className="w-full h-full object-contain rounded-xl transition-transform duration-300 group-hover:scale-110"
                        />
                      </picture>
                    ) : (
                      <img
                        src={app.icon}
                        alt={`${app.name} icon`}
                        width={64}
                        height={64}
                        loading="lazy"
                        className="w-full h-full object-contain rounded-xl transition-transform duration-300 group-hover:scale-110"
                      />
                    )}
                  </div>
                  <span className="text-xs sm:text-sm font-medium text-gray-900">
                    {app.name}
                  </span>
                </div>

                {/* Hover effects */}
                <div className="pointer-events-none absolute -inset-px opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-xl"
                  style={{
                    background: 'radial-gradient(600px circle at var(--mouse-x) var(--mouse-y), rgba(59, 130, 246, 0.08), transparent 40%)'
                  }}
                />
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppMarquee; 
import React, { useEffect, useState } from 'react';
import { Github, Star } from 'lucide-react';

interface GitHubStarButtonProps {
  repo: string;
  className?: string;
}

const GitHubStarButton: React.FC<GitHubStarButtonProps> = ({ repo, className = '' }) => {
  const [starCount, setStarCount] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStarCount = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(`https://api.github.com/repos/${repo}`);
        if (response.ok) {
          const data = await response.json();
          setStarCount(data.stargazers_count);
        } else {
          console.error('Failed to fetch GitHub stars');
        }
      } catch (error) {
        console.error('Error fetching GitHub stars:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStarCount();
  }, [repo]);

  return (
    <a
      href={`https://github.com/${repo}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`flex items-center gap-1.5 px-3 py-1.5 rounded-lg bg-gradient-to-r from-gray-50 to-gray-100 border border-gray-200 shadow-sm hover:shadow-md transition-all duration-300 text-gray-700 hover:text-gray-900 group ${className}`}
      title="Star on GitHub"
    >
      <Github className="w-4 h-4 text-gray-700 group-hover:text-gray-900 transition-colors" />
      <span className="text-sm font-medium">Star</span>
      <div className="flex items-center justify-center bg-yellow-100 rounded-md px-1.5 py-0.5 border border-yellow-200 min-w-[28px]">
        {isLoading ? (
          <div className="w-3 h-3 rounded-full border-2 border-yellow-500 border-t-transparent animate-spin" />
        ) : (
          <>
            <Star className="w-3 h-3 text-yellow-500 fill-yellow-500 mr-0.5" />
            <span className="text-xs font-medium text-yellow-700">
              {starCount !== null ? starCount : '?'}
            </span>
          </>
        )}
      </div>
    </a>
  );
};

export default GitHubStarButton; 
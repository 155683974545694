import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VoiceInkLandingPage from './VoiceInkLandingPage';
import { HelmetProvider } from 'react-helmet-async';
import PreviewCard from './components/PreviewCard';

// Lazy load other pages
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService'));
const Buy = React.lazy(() => import('./components/Buy'));
const BlogPost = React.lazy(() => import('./components/BlogPost'));
const ThankYou = React.lazy(() => import('./components/ThankYou'));
const Features = React.lazy(() => import('./pages/Features'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const Testimonials = React.lazy(() => import('./pages/Testimonials'));
const FAQ = React.lazy(() => import('./pages/FAQ'));
const BestDictationApps = React.lazy(() => import('./pages/BestDictationApps'));
const WisprFlowAlternative = React.lazy(() => import('./pages/wispr-flow-alternative'));
const SuperWhisperAlternative = React.lazy(() => import('./pages/superwhisper-alternative'));

// Analytics component with redirection
const Analytics = () => {
  useEffect(() => {
    window.location.href = 'https://cloud.umami.is/share/CIyBmKcD2n2SW1xD/tryvoiceink.com';
  }, []);
  return null;
};

// Declare the Affonso global variable for TypeScript
declare global {
  interface Window {
    Affonso: {
      signup: (email: string) => void;
    };
    affonso_referral?: string;
  }
}

function App() {
  // Add Affonso tracking script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://affonso.io/js/pixel.min.js';
    script.async = true;
    script.defer = true;
    script.dataset.affonso = 'cm7zxt04w000esni7qhb66uk4';
    script.dataset.cookie_duration = '30';
    document.head.appendChild(script);
    
    return () => {
      // Clean up script on unmount if it exists in the DOM
      const existingScript = document.querySelector('script[src="https://affonso.io/js/pixel.min.js"]');
      if (existingScript && document.head.contains(existingScript)) {
        document.head.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          <Route path="/" element={<VoiceInkLandingPage />} />
          <Route path="/privacy" element={
            <Suspense fallback={<div>Loading...</div>}>
              <PrivacyPolicy />
            </Suspense>
          } />
          <Route path="/terms" element={
            <Suspense fallback={<div>Loading...</div>}>
              <TermsOfService />
            </Suspense>
          } />
          <Route path="/buy" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Buy />
            </Suspense>
          } />
          <Route path="/thank-you" element={
            <Suspense fallback={<div>Loading...</div>}>
              <ThankYou />
            </Suspense>
          } />
          <Route path="/preview" element={<PreviewCard />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/blog/:slug" element={
            <Suspense fallback={<div>Loading...</div>}>
              <BlogPost />
            </Suspense>
          } />
          <Route path="/features" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Features />
            </Suspense>
          } />
          <Route path="/pricing" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Pricing />
            </Suspense>
          } />
          <Route path="/testimonials" element={
            <Suspense fallback={<div>Loading...</div>}>
              <Testimonials />
            </Suspense>
          } />
          <Route path="/faq" element={
            <Suspense fallback={<div>Loading...</div>}>
              <FAQ />
            </Suspense>
          } />
          <Route path="/best-dictation-apps" element={<BestDictationApps />} />
          <Route path="/wispr-flow-alternative" element={<WisprFlowAlternative />} />
          <Route path="/superwhisper-alternative" element={<SuperWhisperAlternative />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;





import React from 'react';

interface DownloadButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  size?: 'default' | 'small';
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ 
  children, 
  className, 
  size = 'default',
  disabled,
  ...props 
}) => {
  const sizeClasses = {
    default: "h-[56px] text-[17px] px-8",
    small: "h-[44px] text-[15px] px-6"
  };

  const iconSize = size === 'default' ? "w-[22px] h-[22px]" : "w-[18px] h-[18px]";

  return (
    <button
      className={`
        relative
        bg-gradient-to-b
        from-gray-800
        to-black
        text-white
        rounded-xl
        font-semibold
        transition-all
        duration-200
        ${!disabled ? 'active:scale-[0.97]' : 'opacity-70 cursor-not-allowed'}
        flex
        items-center
        justify-center
        gap-3
        shadow-[0_8px_16px_-4px_rgba(0,0,0,0.5),0_4px_6px_-2px_rgba(0,0,0,0.4),inset_0_1px_0_0_rgba(255,255,255,0.1)]
        ${!disabled ? 'hover:shadow-[0_12px_20px_-4px_rgba(0,0,0,0.5),0_8px_12px_-2px_rgba(0,0,0,0.4),inset_0_1px_0_0_rgba(255,255,255,0.15)]' : ''}
        ${!disabled ? 'hover:translate-y-[-1px]' : ''}
        after:absolute
        after:inset-0
        after:rounded-xl
        after:bg-gradient-to-b
        after:from-white/[0.12]
        after:to-transparent
        after:opacity-0
        ${!disabled ? 'hover:after:opacity-100' : ''}
        after:transition-opacity
        after:duration-200
        group
        ${sizeClasses[size]}
        ${className}
      `}
      disabled={disabled}
      {...props}
    >
      <svg 
        className={`
          ${iconSize}
          relative
          z-[1]
          transition-colors
          duration-200
          ${!disabled ? 'group-hover:text-blue-400' : ''}
        `}
        viewBox="0 0 24 24" 
        fill="currentColor"
      >
        <path d="M22 17.607c-.786 2.28-3.139 6.317-5.563 6.361-1.608.031-2.125-.953-3.963-.953-1.837 0-2.412.923-3.932.983-2.572.099-6.542-5.827-6.542-10.995 0-4.747 3.308-7.1 6.198-7.143 1.55-.028 3.014 1.045 3.959 1.045.949 0 2.727-1.29 4.596-1.101.782.033 2.979.315 4.389 2.377-3.741 2.442-3.158 7.549.858 9.426zm-5.222-17.607c-2.826.114-5.132 3.079-4.81 5.531 2.612.203 5.118-2.725 4.81-5.531z"/>
      </svg>
      <span className="tracking-[-0.01em] relative z-[1]">
        {children}
      </span>
    </button>
  );
};

export default DownloadButton; 
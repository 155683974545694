import React, { Suspense } from 'react';
import Layout from './components/landing/Layout';
import Hero from './components/Hero';
import MetaTags from './components/landing/MetaTags';

// Lazy load components
const WriteEmails = React.lazy(() => import('./components/WriteEmails'));
const WriteChat = React.lazy(() => import('./components/WriteChat'));
const MoreFeatures = React.lazy(() => import('./components/MoreFeatures'));
const Testimonials = React.lazy(() => import('./components/Testimonials'));
const Pricing = React.lazy(() => import('./components/landing/Pricing'));
const FAQ = React.lazy(() => import('./components/landing/FAQ'));
const OpenSource = React.lazy(() => import('./components/landing/OpenSource'));

export default function VoiceInkLandingPage() {
  return (
    <Layout>
      <MetaTags />
      <Hero />
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <WriteEmails />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <WriteChat />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <MoreFeatures />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <Testimonials />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <Pricing />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <FAQ />
      </Suspense>
      <Suspense fallback={<div className="min-h-[400px] flex items-center justify-center">Loading...</div>}>
        <OpenSource />
      </Suspense>
    </Layout>
  );
}
